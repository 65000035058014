.logo {
    width: 199.486px;
    height: 47.848px;
  }
  
  .header {
      font-size: $default-font-size;
      height: $default-font-size;
      //width: calc(100% - $default-margin * 2);
      margin-left: $default-margin;
      margin-right: $default-margin;
      margin-top: 16px;
      height: 45px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
  }
  
  .headerButtons {
    margin-left: auto;
    margin-right: 40px;
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .headerButton {
    color: $text-color;
    font-family: $default-font-name;
    font-size: $default-font-size;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 100% */
    letter-spacing: -0.18px;
    text-decoration: none;
    margin: auto;
  
  
    &Outlined {
        color: $complementary-green;
        text-align: center;
        font-family: $default-font-name;
        font-size: $outilned-font-size;
        font-style: normal;
        font-weight: 700;
        border: 1px solid $complementary-green;
        border-radius: 31px;
        border-color: $complementary-green;
        margin-top: auto;
        margin-bottom: auto;
        padding: 15.5px;
        text-decoration: none;
    }
  }

  @media screen and (max-width: 1000px)  {
    .header {
        margin-left: 0px;
        margin-right: 0px;
    }
}