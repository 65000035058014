$bg-color: #FFF;
$complementary-green: #46B8C8;
$primary-orange: #FB8958;
$text-color: #3D4F5C;
$secondary-yellow: #FFC351;

$default-font-name: 'Circular Std Medium';
$default-font-size: 18px;
$outilned-font-size: 14px;

$default-margin: 120px;

.App {
  background-color: $bg-color;
  width: 100%;
}

h1 {
  color: $text-color;
  font-family: $default-font-name;
  font-size: 85px;
  font-style: normal;
  font-weight: 700;
  line-height: 85px; /* 100% */
  letter-spacing: -1.7px;
  margin-top: 25px;
  text-indent: 0;
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 65px;
  }
}

h2 {
  color: $text-color;
  font-family: $default-font-name;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 100% */
  letter-spacing: -1px;
  margin-top: 25px;
  text-indent: 0;
}

h3 {
  color: $primary-orange;
  text-align: center;
  font-family: $default-font-name;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 125% */
  letter-spacing: -0.4px;
  text-indent: 0%;
}

h4 {
  color: $text-color;
  font-family: $default-font-name;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 126.667% */
  letter-spacing: -0.3px;
  text-indent: 0;
}

h5 {
  color: $primary-orange;
  font-family: $default-font-name;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 125% */
  letter-spacing: -0.24px;
  margin-top: 25px;
  text-indent: 0;
}

h6 {
  color: $bg-color;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 100% */
  text-indent: 0;
}


@import './Header.scss';
@import './Contentbox.scss';
@import './Messager.scss';
@import './Footer.scss';
@import './Slide.scss';
@import './Button.scss';