.contentbox {
    margin-left: $default-margin;
    margin-right: $default-margin;
    margin-top: 145.44px;
    padding: 40px;
    vertical-align: top;

    .imageOnRight {
        position: absolute;
        right: 0;
        background-color: lightgray 50%;
        background-repeat: no-repeat;
        justify-self: center;
    }

    .imageOnLeft {
        position: absolute;
        left: 0;
        background-color: lightgray 50%;
        background-repeat: no-repeat;
        justify-self: center;
    }

    .imageQuestionmark {
        width: 75px;
        height: 88px;
    }

    .imageThumbLeft, .imageThumbRight {
        display: block;
        position: absolute;
        top: calc(50% - 33px);
        pointer-events: none;
    }

    .imageThumbRight {
        right: 0;
    }

    .imageThumbLeft {
        left: 0;
        transform: scaleX(-1);
    }

    .whoWeAreCountingBoxes {
        display: flex;
        justify-content: space-between;
    }

    .whoWeAreCountingBox {
        margin-top: 79px;
        display: flex;
        flex-direction: column;

    }

    .whoWeAreCountingBoxNumber {
        color: $primary-orange;
        font-family: $default-font-name;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 100% */
        letter-spacing: -0.3px;
    }

    .whoWeAreCountingBreaker {
        display: inline-block;
        width: 50px;
        height: 2px;
    }
}

a {
    display: inline-block;
    //color: $bg-color;
    font-family: $default-font-name;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 100% */
    letter-spacing: -0.18px;
    //background-color: $complementary-green;
    height: 45px;
    border-radius: 32.5px;
    text-decoration: none;
    padding: 13.5px;
    margin-top: 25px;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 100% */
}

.spacebetweenBlock {
    display: flex;
    justify-content: space-between;
}

.contactWrapper {
    background-color: $secondary-yellow;
}

/*.shadowWrapper {
    
}*/

.shadowWrapperColor{
    background-color: #FBFAFA;
    width: 100%;
}

@media screen and (max-width: 1500px)  {
    .imageOnRight, .imageOnLeft {
        display: none;
    }
}

.imageThumbLeft, .imageThumbRight {
    visibility: visible;
}

@media screen and (max-width: 1300px)  {
    .imageThumbLeft, .imageThumbRight {
        visibility: hidden;
    }
}

@media screen and (max-width: 1000px)  {
    .rocketImage {
        display: none;
    }
    .whoWeAreCountingBoxes {
        flex-wrap: wrap;
    }
    .contentbox {
        margin-left: 0px;
        margin-right: 0px;
    }
}