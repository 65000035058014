.slideBlock {
    border-radius: 30px;
    background: $bg-color;
    box-shadow: 0px 8px 35px 0px rgba(187, 169, 142, 0.15);
    margin: 25px;
    padding: 45px;
}

.slideMainText {
    color: black;
    text-align: center;
    font-family: Circular Std;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 93.75% */
    letter-spacing: -0.32px;
}

.slideSubText {
    color: $text-color;
    text-align: center;
    font-family: Circular Std;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.16px;
}

.slideImage {
    border-radius: 49px;
    background-color: lightgray 50%;
    width: 100%;
    height: auto;
}

.slideGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    width: 100%;
}

@media screen and (max-width: 1000px)  {
    .slideGrid {
        grid-template-columns: 1fr;
    }

}